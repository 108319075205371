<template>
  <div class="row filter-container">
    <q-card class="col-12">
      <q-card-title>
        <h5 class="q-mb-none q-mt-none">
          Select your Node
        </h5>
        <div v-if="canDisplayselectBtn"
             class="btn-select-container"
        >
          <q-btn size="11px"
                 color="grey"
                 @click="select(currentSelected)"
          >
            Select "{{ currentSelected.name }}"
          </q-btn>
        </div>
      </q-card-title>
      <q-card-separator />
      <q-card-main v-if="topology!==null && selected.length">
        <div
          class="row select-container"
        >
          <div class="col-6">
            <ul class="selected">
              <li v-for="t in selected"
                  :key="t.id"
                  @click="navigateBack(t)"
              >
                <div class="row q-mb-xs cursor-pointer">
                  <div class="col">
                    <q-icon :name="nodeIcon[t.nodeType]" /> {{ t.name }}
                    <span class="select-btn">
                      <q-icon name="fa fa-check" />
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-6">
            <ul>
              <li v-for="t in currentSelected.next"
                  :key="t.id"
                  @click="navigate(t)"
              >
                <div class="row  q-mb-xs cursor-pointer">
                  <div class="col-auto">
                    <q-icon :name="nodeIcon[t.nodeType]" /> {{ t.name }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </q-card-main>
      <q-card-main v-else>
        <div class="row">
          <div class="col text-center">
            <qt-spinner size="80"
                        class="spinner-loader"
            />
          </div>
        </div>
      </q-card-main>
    </q-card>
  </div>
</template>

<script>
import _ from 'lodash';
import QtSpinner from '@/app/components/ui/spinner.vue';
import { mapState } from 'vuex';

export default {
  name: 'TopologyFilter',
  components: { QtSpinner },
  props: {
    types: {
      type: Array,
      default: () => ['plug'],
    },
    closeOnFirstFind: {
      type: Boolean,
      default: false,
    },
    addSelectBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      nodeIcon: {
        root: 'fas fa-globe',
        country: 'far fa-flag',
        city: 'fa fa-compass', // fa-compress
        building: 'far fa-building',
        floor: 'fa fa-align-justify',
        premise: 'far fa-copyright',
        room: 'fa fa-door-open',
        plug: 'fas fa-plug',
      },
    };
  },
  computed: {
    ...mapState('oql', ['topology']),
    currentSelected() {
      return this.selected[this.selected.length - 1];
    },
    canDisplayselectBtn() {
      return this.addSelectBtn && !_.isUndefined(this.currentSelected) && !_.isUndefined(this.currentSelected.uuid);
    },
  },
  watch: {
    topology(val) {
      if (val) this.selected.push(val);
    },
  },
  mounted() {
    if (this.topology) this.selected.push(this.topology);
  },
  methods: {
    navigate(topologyNode) {
      this.selected.push(topologyNode);
      if (this.addSelectBtn) return;
      const hasCorrectNodeType = this.types === '*' || _.indexOf(this.types, topologyNode.nodeType) !== -1;
      if (!_.isUndefined(topologyNode.uuid) && hasCorrectNodeType) {
        this.$emit('input', topologyNode.uuid);
        this.$emit('select', topologyNode);
        if (this.closeOnFirstFind) {
          this.$emit('close');
        }
      }
    },
    select(topologyNode) {
      this.$emit('input', topologyNode.uuid);
      this.$emit('select', topologyNode);
      this.$emit('close');
    },
    navigateBack(topologyNode) {
      let stop = false;
      this.selected = _.filter(this.selected, s => {
        if (s._id === topologyNode._id) {
          stop = true;
          return true;
        }
        return !stop;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

  .cursor-pointer
    cursor pointer
  ul li div
    color black
    width 100%
    padding 5px
    border-radius 4px
    background #f3f3f3
    i
      font-size 0.9em
      margin-right 10px
  ul li
    margin 10px
  ul.selected li:last-child div
    background #aaaaaa
    color white

  .filter-container
    width 500px

  span.select-btn
    display none

  ul.selected li:last-child span.select-btn
    display inline-block
    float: right

  .q-card-title
    position relative
    .btn-select-container
      position absolute
      right 15px
      top 15px
  .spinner-loader
    stroke $primary
    fill $primary

  .select-container
    max-height 400px
    overflow auto

::-webkit-scrollbar {
    width: 3px;
}
::-webkit-scrollbar-track {
    background: #2f2f2f;
}
::-webkit-scrollbar-thumb {
    background: #828282;
}
::-webkit-scrollbar-thumb:hover {
    background: #b9b9b9;
}
</style>
