<template>
  <div class="location">
    <q-input v-model="selNames"
             clearable
             class="location__device"
             placeholder="Select a device"
             stackLabel="Device UUID">
      <q-autocomplete :maxResults="10"
                      :debounce="50"
                      separator
                      @search="searchDevice" />
    </q-input>
    <select-topology class="location__node"
                     addSelectBtn />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SelectTopology from '@/app/pages/oql/components/Topology/SelectTopology.vue';
import Fuse from 'fuse.js';
import DebounceMixin from '@/app/mixins/debounce';

export default {
  name: 'Location',
  components: { SelectTopology },
  mixins: [DebounceMixin],
  data() {
    return {
      selNames: [],
    };
  },
  computed: {
    ...mapState('oql', ['devices', 'builder']),
  },
  watch: {
    selNames(val) {
      this.setBuilderNames(val);
      if (val.length === 0 || val.length === 32) this.debounce(this.sendQuery, 1000);
    },
  },
  mounted() {
    this.selNames = this.builder.names;
    this.fetchDevices();
  },
  methods: {
    ...mapMutations('oql', {
      setBuilderNames: 'SET_BUILDER_NAMES',
    }),
    ...mapActions('oql', ['fetchDevices', 'sendQuery']),
    searchDevice(terms, done) {
      const fuse = new Fuse(this.devices, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['label', 'sublabel'],
      });
      const devicesFound = fuse.search(terms);
      done(devicesFound);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.location
  padding 20px
  .location__device, .location__node
    width 600px
    border-bottom solid $border-light $grey
  .location__device
    margin 0 auto
  .location__node
    margin 20px auto 0 auto
</style>
