<template>
  <div class="sidebar-in">
    <SidebarItem class="sidebar-item"
                 title="time">
      <TimeControl />
    </SidebarItem>
    <SidebarItem class="sidebar-item"
                 title="display">
      <div class="row">
        <div class="col-8">
          <data-select />
        </div>
        <div class="col-4">
          <display-options />
        </div>
      </div>
    </SidebarItem>
    <SidebarItem class="sidebar-item"
                 title="location">
      <location />
    </SidebarItem>
    <SidebarItem class="sidebar-item"
                 title="functions">
      <functions />
    </SidebarItem>
  </div>
</template>

<script>
import SidebarItem from '@/app/components/layout/sidebar/sidebar-item.vue';
import TimeControl from '@/app/pages/oql/components/selection/time.vue';
import Location from '@/app/pages/oql/components/selection/location.vue';
import DataSelect from '@/app/pages/oql/components/DataSelect/DataSelect.vue';
import Functions from '@/app/pages/oql/components/FunctionSelect/select.vue';
import DisplayOptions from '@/app/pages/oql/components/selection/display-options.vue';

export default {
  name: 'SidebarIn',
  components: {
    SidebarItem,
    TimeControl,
    Location,
    Functions,
    DataSelect,
    DisplayOptions,
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.sidebar-in
  overflow auto
  height 100%
  .sidebar-item
    border-bottom $border-thick solid $silver
    &:last-child
      border-bottom none
</style>
