<template>
  <div>
    <q-input v-model="selected"
             :color="selected ? 'black': 'grey'"
             inverted
             icon="place"
             class="full-width"
             clearable
             stackLabel="Condition field"
             placeholder="None"
    />
    <q-popover v-model="openedSelect">
      <q-list separator
              link
      >
        <q-item v-if="!firstState"
                @click.native="navigateBack()"
        >
          <q-icon name="arrow_back_ios" /><span class="text-bold">
            Back
          </span>
        </q-item>
        <q-item v-for="t in currentSelected"
                v-if="!lastState"
                :key="t.value"
                @click.native="navigate(t)"
        >
          <q-item-main :label="t.label" />
          <q-item-side right
                       icon="arrow_right"
          />
        </q-item>
        <q-item v-for="t in currentSelected"
                v-if="lastState"
                :key="t.value"
                @click.native="selected = t.value"
        >
          <q-item-side left>
            <q-radio v-model="selected"
                     :val="t.value"
                     color="amber"
                     checkedIcon="fas fa-check-circle"
                     uncheckedIcon="far fa-circle"
            />
          </q-item-side>
          <q-item-main :label="t.label" />
        </q-item>
      </q-list>
    </q-popover>
  </div>
</template>

<script>
import fields from './fields.json';

export default {
  name: 'FieldSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      openedSelect: false,
      parentNum: 0,
      popover: false,
      selected: '',
      firstState: true,
      parentSelected: null,
      lastState: false,
      parent: [],
      currentSelected: null,
      display: [],
    };
  },
  computed: {
    buttonLabel() {
      return this.selected ? this.selected : 'Condition Field';
    },
  },
  watch: {
    selected(newVal) {
      this.$emit('input', newVal);
    },
  },
  created() {
    this.display = fields;
    this.currentSelected = this.display;
    this.selected = this.value;
  },
  methods: {
    navigate(node) {
      this.parent.push(this.currentSelected);
      this.parentSelected = node;
      this.currentSelected = node.children;
      this.firstState = false;
      if (!this.currentSelected[0].children || this.currentSelected[0].children.length === 0) {
        this.lastState = true;
      }
    },
    navigateBack() {
      this.currentSelected = this.parent.pop();
      this.firstState = this.currentSelected === this.display;
      this.lastState = false;
    },
  },
};
</script>

<style>
</style>
