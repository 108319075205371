<template>
  <div class="row q-pa-sm">
    <div class="col-12">
      <div v-for="(f, index) of functions"
           :key="f.fnc"
           class="row gutter-sm q-mb-sm">
        <div class="col">
          <q-select v-model="f.fnc"
                    :color="f.fnc ? 'black': 'grey'"
                    :options="funcChoices"
                    inverted
                    placeholder="None"
                    stackLabel="Condition Function"
                    filter="filter"
                    autofocusFilter="true"
                    clearable
                    filterPlaceholder="Search"
                    @input="addFunction(index)" />
        </div>
        <div v-if="f.fnc"
             class="col">
          <field-select v-model="f.field" />
        </div>
        <div v-if="f.fnc"
             class="col">
          <q-select v-model="f.sign"
                    :color="f.sign ? 'black': 'grey'"
                    :options="signs"
                    inverted
                    placeholder="None"
                    stackLabel="Condition Sign"
                    filter="filter"
                    autofocusFilter="true"
                    clearable
                    filterPlaceholder="Search" />
        </div>
        <div v-if="f.fnc"
             class="col">
          <q-select v-if="valuesOptions(f.field).length > 0"
                    v-model="f.value"
                    :color="f.value ? 'black': 'grey'"
                    :options="valuesOptions(f.field)"
                    clearable
                    placeholder="Choose value"
                    inverted
                    stackLabel="Condition Value" />
          <q-input v-else
                   v-model="f.value"
                   :color="f.value ? 'black': 'grey'"
                   clearable
                   placeholder="Insert value"
                   inverted
                   stackLabel="Condition Value">
            <q-autocomplete v-if="activeAutocomplete('nodes', f.field)"
                            @search="searchNodesUuid" />
            <q-autocomplete v-if="activeAutocomplete('devices', f.field)"
                            @search="searchDevicesUuid" />
          </q-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldSelect from '../DataSelect/FieldSelect.vue';
import fieldsValues from './fields-values.json';
import Fuse from 'fuse.js';
import { mapState, mapMutations, mapActions } from 'vuex';
import DebounceMixin from '@/app/mixins/debounce';

export default {
  name: 'FunctionSelect',
  components: { FieldSelect },
  mixins: [DebounceMixin],
  props: {
    oncreateSelected: { type: Array, default: () => [] },
    devicesNames: { type: Array, default: () => [] },
  },
  data() {
    return {
      functions: [],
      nodesUuid: [],
      funcChoices: [
        { label: 'Value of', value: 'value' },
        { label: 'Mean', value: 'MEAN' },
        { label: 'Max', value: 'MAX' },
        { label: 'Min', value: 'MIN' },
      ],
      signs: [{ label: 'Greater than (>)', value: '>' }, { label: 'Less than (<)', value: '<' }, { label: 'Equal to', value: '=' }],
    };
  },
  computed: {
    ...mapState('oql', ['builder']),
  },
  watch: {
    functions(newVal) {
      this.setBuilderFunctions(newVal);
      this.debounce(this.sendQuery, 1000);
    },
  },
  mounted() {
    this.functions = this.builder.functions;
    if (this.functions.length === 0) this.functions.push({ fnc: '' });
  },
  methods: {
    ...mapMutations('oql', {
      setBuilderFunctions: 'SET_BUILDER_FUNCTIONS',
    }),
    ...mapActions('oql', ['sendQuery']),
    valuesOptions(field) {
      const options = fieldsValues.find(x => x.field === field);
      return options ? options.options : [];
    },
    addFunction(index) {
      if (this.functions[index] && !this.functions[index].fnc) {
        this.functions.splice(index, 1);
      }
      for (const f of this.functions) {
        if (!f.fnc) {
          return;
        }
      }
      this.functions.push({
        fnc: '',
      });
    },
    activeAutocomplete(type, field) {
      if (!field) return false;
      if (type === 'nodes') return field === 'places.plug.uuid';
      return field === 'devices.uuid';
    },
    searchNodesUuid(terms, done) {
      const fuse = new Fuse(this.nodesUuid, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['plug'],
      });
      const nodeFound = fuse.search(terms);
      done(nodeFound.map(x => (x = { label: x.plug, value: x.plug, sublabel: x.name })));
    },
    searchDevicesUuid(terms, done) {
      const fuse = new Fuse(this.devicesNames, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['label', 'sublabel'],
      });
      const devicesFound = fuse.search(terms);
      done(devicesFound);
    },
  },
};
</script>

<style lang="stylus" scoped>
.main-card
  background-color white
</style>
