<template>
  <div class="options">
    <q-input v-model="limit"
             clearable
             type="number"
             class="options__limit"
             placeholder="No limit"
             stackLabel="Number of results"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import DebounceMixin from '@/app/mixins/debounce';

export default {
  name: 'DisplayOptions',
  components: {},
  mixins: [DebounceMixin],
  data() {
    return {
      limit: 100,
    };
  },
  computed: {
    ...mapState('oql', ['builder']),
  },
  watch: {
    limit(val) {
      this.setBuilderLimit(isNaN(val) ? null : val);
      this.debounce(this.sendQuery, 1000);
    },
  },
  mounted() {
    this.limit = this.builder.limit;
  },
  methods: {
    ...mapMutations('oql', {
      setBuilderLimit: 'SET_BUILDER_LIMIT',
    }),
    ...mapActions('oql', ['sendQuery']),
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'
.options
    padding-right 50px
    .options__limit
        border-bottom solid $border-light $grey
</style>
