<template>
  <div class="list-items">
    <q-item v-if="!firstState"
            link
            @click.native="navigateBack()"
    >
      <q-icon name="arrow_back_ios" /><span class="text-bold">
        Back
      </span>
    </q-item>
    <q-item v-for="t in currentSelected"
            v-if="!lastState"
            :key="t.value"
            class="list-items__item"
            link
            @click.native="navigate(t)"
    >
      <q-item-side left>
        <q-checkbox v-model="selected"
                    :val="t.value"
                    color="black"
                    @input="selectChildren(t)"
        />
      </q-item-side>
      <q-item-main :label="t.label">
        <q-item-tile v-if="hasChildrenSelected(t.value)"
                     sublabel
                     color="black"
        >
          Child(ren) selected
        </q-item-tile>
      </q-item-main>
      <q-item-side right
                   icon="arrow_right"
      />
    </q-item>
    <q-item v-for="t in currentSelected"
            v-if="lastState"

            :key="t.value"
            class="list-items__item"
            link
    >
      <q-item-side left>
        <q-checkbox v-model="selected"
                    :val="t.value"
                    color="black"
                    @input="uncheckParent()"
        />
      </q-item-side>
      <q-item-main :label="t.label"
                   @click.native="checkLabel(t.value)"
      />
      <q-item-side v-if="t.value.split('.')[0] === 'data'"
                   right
      >
        <q-btn-dropdown
          v-if="selected.find(x => x === t.value)"
          color="black"
          size="sm"
          flat
          label="Options"
        >
          <q-list
            separator
            link
          >
            <q-item v-for="f in functions"
                    :key="f"
                    v-close-overlay
                    @click.native="addFunction(t.value, f)"
            >
              {{ f }}()
              <q-item-side v-if="columnsFunctions.find(x => x.value === t.value && x.func === f)"
                           right
              >
                <q-icon name="check" />
              </q-item-side>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-item-side>
    </q-item>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import DebounceMixin from '@/app/mixins/debounce';

export default {
  name: 'DataSelect',
  mixins: [DebounceMixin],
  props: {
    oncreateSelected: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      parentNum: 0,
      popover: false,
      selected: [],
      firstState: true,
      parentSelected: null,
      lastState: false,
      parent: null,
      columnsFunctions: [],
      functions: ['MIN', 'MAX', 'MEAN', 'VALUE'],
      currentSelected: null,
      display: [
        {
          label: 'Data',
          value: 'data',
          children: [
            {
              label: 'Temperature',
              value: 'data.temperature',
            },
            {
              label: 'Luminosity',
              value: 'data.luminosity',
            },
            {
              label: 'Humidity',
              value: 'data.humidity',
            },
            {
              label: 'Power',
              value: 'data.power',
            },
            {
              label: 'COV',
              value: 'data.cov',
            },
            {
              label: 'Noise',
              value: 'data.noise',
            },
            {
              label: 'CO2',
              value: 'data.co2',
            },
          ],
        },
        {
          label: 'Devices',
          value: 'devices',
          children: [
            {
              label: 'Name',
              value: 'devices.name',
            },
            {
              label: 'UUID',
              value: 'devices.uuid',
            },
            {
              label: 'Creation Date',
              value: 'devices.creationDate',
            },
          ],
        },
        {
          label: 'Places',
          value: 'places',
          children: [
            {
              label: 'Country',
              value: 'places.country',
            },
            {
              label: 'City',
              value: 'places.city',
            },
            {
              label: 'Building',
              value: 'places.building',
            },
            {
              label: 'Floor',
              value: 'places.floor',
            },
            {
              label: 'premise',
              value: 'places.premise',
            },
            {
              label: 'Room',
              value: 'places.room',
            },
            {
              label: 'Space',
              value: 'places.space',
            },
            {
              label: 'Plug',
              value: 'places.plug',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState('oql', ['builder']),
  },
  watch: {
    selected(newVal) {
      this.setBuilderColumns({ selected: newVal, functions: this.columnsFunctions });
      this.debounce(this.sendQuery, 1000);
    },
  },
  mounted() {
    this.currentSelected = this.display;
    this.selected = this.builder.columns.selected ? this.builder.columns.selected : [];
    this.columnsFunctions = this.builder.columns.functions ? this.builder.columns.functions : [];
  },
  methods: {
    ...mapMutations('oql', {
      setBuilderColumns: 'SET_BUILDER_COLUMNS',
    }),
    ...mapActions('oql', ['sendQuery']),
    addFunction(value, func) {
      const elem = this.columnsFunctions.find(x => x.value === value);
      if (elem) elem.func = func;
      else this.columnsFunctions.push({ value: value, func: func });
      this.setBuilderColumns({ selected: this.selected, functions: this.columnsFunctions });
      this.debounce(this.sendQuery, 1000);
    },
    navigate(node) {
      this.parent = this.currentSelected;
      this.parentSelected = node;
      this.currentSelected = node.children;
      this.firstState = false;
      if (!this.currentSelected.children || this.currentSelected.children.length === 0) {
        this.lastState = true;
      }
    },
    navigateBack() {
      this.currentSelected = this.parent;
      this.firstState = this.currentSelected === this.display;
      this.lastState = false;
    },
    selectChildren(node) {
      let remove = true;
      if (this.selected.includes(node.value)) {
        this.parentNum++;
        remove = false;
      } else {
        this.parentNum--;
      }
      for (const c of node.children) {
        if (!remove && !this.selected.includes(c.value)) {
          this.selected.push(c.value);
        } else if (remove) {
          this.selected = this.selected.filter(x => x !== c.value);
        }
      }
    },
    checkLabel(value) {
      this.selected.includes(value) ? (this.selected = this.selected.filter(x => x !== value)) : this.selected.push(value);
      this.uncheckParent();
    },
    uncheckParent() {
      this.columnsFunctions = this.columnsFunctions.filter(x => this.selected.find(y => y === x.value));
      let allSelect = true;
      for (const c of this.currentSelected) {
        if (!this.selected.includes(c.value)) {
          allSelect = false;
        }
      }
      if (allSelect && !this.selected.includes(this.parentSelected.value)) {
        this.parentNum++;
        this.selected.push(this.parentSelected.value);
      } else if (!allSelect && this.selected.includes(this.parentSelected.value)) {
        this.parentNum--;
        this.selected = this.selected.filter(x => x !== this.parentSelected.value);
      }
    },
    hasChildrenSelected(parent) {
      return this.selected.find(x => x.split('.')[0] === parent);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'
.list-items
  padding 10px
  .list-items__item
    font-size $fs-h3
    font-weight 700
</style>
