<template>
  <div style="height: 100%">
    <q-layout ref="layout"
              class="building-facts">
      <q-layout-drawer :width="709"
                       :value="true"
                       class="left-sidebar no-transition"
                       side="left">
        <SidebarIn />
      </q-layout-drawer>
      <q-page-container class="building-facts__center">
        <div class="viewer">
          <div v-if="!loading"
               class="column justify-around">
            <div v-for="c in formattedData"
                 :key="c.name">
              <q-table :binaryStateSort="true"
                       :title="c.label"
                       :columns="c.columns"
                       :data="c.data"
                       rowKey="time"
                       class="results-table"
                       dense
                       color="black" />
            </div>
          </div>
          <qt-spinner v-if="loading"
                      size="80"
                      class="spinner-loader" />
        </div>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import SidebarIn from '@/app/pages/oql/views/sidebar-in.vue';
import QtSpinner from '@/app/components/ui/spinner.vue';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'QueryBuilder',
  components: {
    SidebarIn,
    QtSpinner,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('oql', ['loading', 'oqlError']),
    ...mapGetters('oql', ['formattedData']),
  },
  watch: {
    oqlError(val) {
      if (val)
        this.$q.notify({
          message: val,
          type: 'warning',
          textColor: 'black',
          icon: 'warning',
          position: 'top',
          timeout: 0,
          actions: [
            {
              label: 'Dismiss',
              handler: () => {},
            },
          ],
        });
    },
  },
  mounted() {
    this.setQueryMode(false);
    this.fetchTopology();
  },
  methods: {
    ...mapActions('oql', ['fetchTopology']),
    ...mapMutations('oql', {
      setQueryMode: 'SET_QUERY_MODE',
    }),
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.building-facts
  min-height auto
  height 100%
  .left-sidebar >>> aside
    position fixed
    top 50px
    overflow visible // to see the drawer
    box-shadow $shadow-elevation-3
  .left-sidebar.no-transition >>> aside
    transform translateX(0px)
  .sidebar-out
    z-index 2

.viewer
  position relative
  padding 10px
  .results-table
    margin 10px auto
    background-color white
  .spinner-loader
    position absolute
    top 300px
    left 50%
    margin-left -40px

::-webkit-scrollbar
  width 6px

::-webkit-scrollbar-track
  background #cacaca

::-webkit-scrollbar-thumb
  background #828282

::-webkit-scrollbar-thumb:hover
  background #000
</style>
