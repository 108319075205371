<template>
  <div>
    <q-input ref="topology-input"
             v-model="selected"
             icon="place"
             class="full-width"
             clearable
             stackLabel="Node UUID"
             placeholder="Choose a node"
             @click="openedTopology = !openedTopology" />
    <transition name="fade">
      <Drawer v-if="openedTopology"
              v-on-clickaway="away"
              :side="$q.platform.is.mobile ? 'right' : 'left'"
              :style="drawerStyle"
              posY="165px"
              class="topology-drawer">
        <topology-filter v-model="selected"
                         :types="types"
                         :addSelectBtn="addSelectBtn"
                         :closeOnFirstFind="closeOnFirstFind"
                         @close="openedTopology = false"
                         @select="selectTopology" />
      </Drawer>
    </transition>
  </div>
</template>

<script>
import TopologyFilter from '../Topology/Filter.vue';
import Drawer from '@/app/components/ui/drawer.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapMutations, mapState, mapActions } from 'vuex';
import DebounceMixin from '@/app/mixins/debounce';

export default {
  name: 'SelectTopology',
  components: { TopologyFilter, Drawer },
  directives: { onClickaway: onClickaway },
  mixins: [DebounceMixin],
  props: {
    helper: {
      type: String,
      default: '',
    },
    value: { type: String, default: '' },
    types: {
      type: Array,
      default: () => ['plug'],
    },
    closeOnFirstFind: {
      type: Boolean,
      default: false,
    },
    addSelectBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openedTopology: false,
      topologyHelperName: this.helper,
      selected: '',
    };
  },
  computed: {
    ...mapState('oql', ['builder']),
    buttonLabel() {
      return this.selected.length > 0 ? this.topologyHelperName : 'Choose a node';
    },
    drawerStyle() {
      const inputTop = this.$refs['topology-input'].$el.getBoundingClientRect().top;
      if (this.openedTopology) return `top: ${inputTop - 194}px;`;
      return '';
    },
  },
  watch: {
    selected(newVal) {
      this.setBuilderNode(newVal);
      this.debounce(this.sendQuery, 1000);
    },

    /**
     * When the parent component changes the value of v-model to an empty value,
     * values are reinitialized here too
     * @param newVal
     */
    value(newVal) {
      if (newVal === '') {
        this.selected = '';
        this.topologyHelperName = '';
      }
    },
  },
  mounted() {
    this.selected = this.builder.node;
  },
  methods: {
    ...mapMutations('oql', {
      setBuilderNode: 'SET_BUILDER_NODE',
    }),
    ...mapActions('oql', ['sendQuery']),
    selectTopology(topologyNode) {
      this.topologyHelperName = topologyNode.name;
    },
    away() {
      this.openedTopology = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.topology-drawer
  position absolute
  left 100%
  min-height 210px
  transition all 0.3s ease
  @media screen and (max-width: 500px)
    top 170px
    right 0
    left 0
    z-index 100
    margin 0 auto

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
</style>
