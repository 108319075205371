<template>
  <div class="control-time">
    <div class="button-container">
      <button v-for="(btn, idx) in timeRangeButtons"
              :key="idx"
              :class="buttonClass(btn.selected)"
              class="o-button o-button--shadows"
              @click="selectTimeRange(btn)">
        {{ btn.timeRange }}
      </button>
    </div>
    <div class="date-input-container">
      <span :class="selectedClass('startDate')"
            class="control-time__date-input theme-dark"
            @click.stop.self="openDrawer('startDate')">
        {{ formatedDateTime.startDate }}
      </span>
      <span :class="selectedClass('startTime')"
            class="date-input__hours"
            @click.stop.self="openDrawer('startTime')">
        {{ formatedDateTime.startTime }}
      </span>
      <ChevronIcon class="date-input__chevron"
                   size="10px" />
      <span :class="selectedClass('endDate')"
            class="control-time__date-input theme-dark"
            @click.stop.self="openDrawer('endDate')">
        {{ formatedDateTime.endDate }}
      </span>
      <span :class="selectedClass('endTime')"
            class="date-input__hours"
            @click.stop.self="openDrawer('endTime')">
        {{ formatedDateTime.endTime }}
      </span>
    </div>
    <transition name="fade">
      <Drawer v-if="drawer.startDate || drawer.endDate || drawer.startTime || drawer.endTime"
              v-on-clickaway="away"
              :side="$q.platform.is.mobile ? 'right' : 'left'"
              posY="70px"
              class="calendar-drawer">
        <q-datetime-picker v-show="drawer.startDate"
                           v-model="timeRange.startDate"
                           :max="timeRange.endDate"
                           minimal
                           format24h
                           dark
                           color="white"
                           type="date"
                           @input="unselectTimeRange()" />
        <q-datetime-picker v-show="drawer.endDate"
                           v-model="timeRange.endDate"
                           :min="timeRange.startDate"
                           :max="today"
                           minimal
                           dark
                           format24h
                           color="white"
                           type="date"
                           @input="unselectTimeRange()" />
        <time-picker v-show="drawer.startTime"
                     v-model="timeRange.startTime"
                     :max="endDateTime"
                     :opened="drawer.startTime"
                     :date="formatedDateTime.startDate"
                     name="startTime"
                     @input="unselectTimeRange()" />
        <time-picker v-show="drawer.endTime"
                     v-model="timeRange.endTime"
                     :min="startDateTime"
                     :opened="drawer.endTime"
                     :date="formatedDateTime.endDate"
                     :max="today"
                     name="endTime"
                     @input="unselectTimeRange()" />
      </Drawer>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import DebounceMixin from '@/app/mixins/debounce';
import ChevronIcon from '@/app/components/ui/pictos/chevron.vue';
import Drawer from '@/app/components/ui/drawer.vue';
import TimePicker from '@/app/components/inputs/time-picker.vue';
import { directive as onClickaway } from 'vue-clickaway';

export default {
  name: 'ControlTime',
  components: {
    ChevronIcon,
    Drawer,
    TimePicker,
  },
  directives: { onClickaway: onClickaway },
  mixins: [DebounceMixin],
  data() {
    return {
      drawer: {
        startDate: false,
        endDate: false,
        startTime: false,
        endTime: false,
      },
      timeRange: {
        startDate: undefined,
        endDate: undefined,
        startTime: undefined,
        endTime: undefined,
      },
      timeRangeButtons: [
        {
          timeRange: 'year',
          selected: false,
        },
        {
          timeRange: 'month',
          selected: false,
        },
        {
          timeRange: 'week',
          selected: false,
        },
        {
          timeRange: 'day',
          selected: false,
        },
        {
          timeRange: 'hour',
          selected: true,
        },
      ],
      today: null,
      timeRangeSelected: true,
    };
  },
  computed: {
    dateFrom() {
      return this.$store.state.selections.time.from;
    },
    dateTo() {
      return this.$store.state.selections.time.to;
    },
    formatedDateTime() {
      return {
        startDate: moment(this.timeRange.startDate).format('DD.MM.YY'),
        endDate: moment(this.timeRange.endDate).format('DD.MM.YY'),
        startTime: moment(this.timeRange.startTime).format('HH[H]mm'),
        endTime: moment(this.timeRange.endTime).format('HH[H]mm'),
      };
    },
    selectedClass() {
      return type => (this.drawer[type] ? 'selectedDrawer' : '');
    },
    startDateTime() {
      return moment(`${this.formatedDateTime.startDate} ${this.formatedDateTime.startTime}`, 'DD.MM.YY HH[H]mm');
    },
    endDateTime() {
      return moment(`${this.formatedDateTime.endDate} ${this.formatedDateTime.endTime}`, 'DD.MM.YY HH[H]mm');
    },
  },
  watch: {
    timeRange: {
      handler(val) {
        this.setDate();
      },
      deep: true,
    },
  },
  mounted() {
    this.today = moment()
      .utc()
      .format();
    if (this.dateFrom && this.dateTo) {
      this.timeRange.startDate = this.timeRange.startTime = this.dateFrom;
      this.timeRange.endDate = this.timeRange.endTime = this.dateTo;
      this.resetSelectedButtons();
    } else this.setTimeRangeDate('hour');
    this.$store.commit('selections/setTimeRange', 'day');
  },
  methods: {
    ...mapActions('oql', ['sendQuery']),
    buttonClass(selected) {
      return {
        'o-button--black': selected,
        'o-button--hover': !selected,
      };
    },
    setDate() {
      this.$store.commit('selections/setTime', {
        from: this.startDateTime.format(),
        to: this.endDateTime.format(),
      });
      this.debounce(this.sendQuery, 1000);
    },
    selectTimeRange(btn) {
      this.timeRangeSelected = true;
      this.setTimeRangeDate(btn.timeRange);
      this.resetSelectedButtons();
      btn.selected = !btn.selected;
    },
    resetSelectedButtons() {
      this.timeRangeButtons.forEach(btn => (btn.selected = false));
    },
    setTimeRangeDate(timeRange) {
      let startDate = moment();
      if (timeRange === 'year') startDate = startDate.subtract(1, 'years');
      else if (timeRange === 'month') startDate = startDate.subtract(1, 'months');
      else if (timeRange === 'week') startDate = startDate.subtract(1, 'weeks');
      else if (timeRange === 'day') startDate = startDate.subtract(1, 'days');
      else if (timeRange === 'hour') startDate = startDate.subtract(1, 'hour');
      this.timeRange.startDate = startDate;
      this.timeRange.endDate = this.today;
      this.timeRange.startTime = startDate;
      this.timeRange.endTime = this.today;
    },
    unselectTimeRange() {
      if (!this.timeRangeSelected) this.resetSelectedButtons();
    },
    away() {
      this.resetAllDrawers();
    },
    resetAllDrawers(type = null) {
      Object.keys(this.drawer).forEach(k => {
        if (!type || type !== k) this.drawer[k] = false;
      });
    },
    openDrawer(type) {
      this.timeRangeSelected = false;
      if (type === 'startDate') this.drawer.startDate = !this.drawer.startDate;
      else if (type === 'endDate') this.drawer.endDate = !this.drawer.endDate;
      else if (type === 'startTime') this.drawer.startTime = !this.drawer.startTime;
      else this.drawer.endTime = !this.drawer.endTime;
      this.resetAllDrawers(type);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.control-time
  padding 16px 8px
  padding-top 0
  .button-container
    display flex
    justify-content center
    margin-bottom 24px
    .o-button
      margin 0 6px
      padding 2px 11px
      background-color white
      text-transform uppercase
      font-weight 400
  .date-input-container
    display flex
    justify-content center
    .control-time__date-input
      z-index 10
      margin-right 5px
      padding 2px
      height 30px
      font-size $fs-h2
      cursor pointer
      user-select none
    .date-input__chevron
      margin 0 12px
      width 20px
      height @width
      transform rotate(-90deg)
    .date-input__hours
      z-index 5
      padding 2px
      height 30px
      color $grey
      font-size 1.3rem
      cursor pointer
      user-select none
    .selectedDrawer
      height 23px
      border-radius 4px
      background-color black
      color white
  .calendar-drawer
    position absolute
    top 23px
    left 100%
    transition all 0.3s ease
    @media screen and (max-width: 500px)
      top 170px
      right 0
      left 0
      z-index 100
      margin 0 auto

.fade-enter-active, .fade-leave-active
  opacity 1

.fade-enter, .fade-leave-to
  opacity 0
</style>
